import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Provider } from 'react-redux';
import { ReactEpubViewer } from 'react-epub-viewer';
import { useLocation } from 'react-router-dom';
import Epub, { EpubCFI } from 'epubjs';

// containers
import Header from 'containers/Header';
import Footer from 'containers/Footer';
import Nav from 'containers/menu/Nav';
import Option from 'containers/menu/Option';
import Snackbar from 'containers/commons/Snackbar';
// components
import ViewerWrapper from 'components/commons/ViewerWrapper';
import LoadingView from 'LoadingView';
// slices
import store from 'slices';
import {
  updateBook,
  updateCurrentPage,
  updateCurrentPageNumber,
  updateToc,
} from 'slices/book';
// hooks
import useMenu from 'lib/hooks/useMenu';
// styles
import 'lib/styles/readerStyle.css';
import viewerLayout from 'lib/styles/viewerLayout';
// types
import { RootState } from 'slices';
import { ViewerRef } from 'types';
import Book, { BookStyle, BookOption } from 'types/book';
import Page from 'types/page';
import Toc from 'types/toc';
import { getInitialPage, setInitialPage } from 'utils/initialPages';
import { Loader } from 'components/Loader';
import useHighlight from 'lib/hooks/useHighlight';
import ContextMenu from './commons/ContextMenu';
import { cfiRangeSpliter } from 'lib/utils/commonUtil';

const DEFAULT_FONT_SIZE = 18;
const H_FACTOR = 1.12;
const W_FACTOR = 1.16;
const F_FACTOR = 1.08;

const Reader = ({ loadingView }: Props) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector<RootState, Page>(
    state => state.book.currentLocation,
  );
  const currentPageNumber = useSelector<RootState, number>(
    state => state.book.currentPageNumber,
  );

  const routerLocation = useLocation();

  const query = new URLSearchParams(routerLocation.search);
  const sampleBook = Epub(`${query.get('book') as string}`);

  const [isContextMenu, setIsContextMenu] = useState<boolean>(false);
  const onContextmMenuRemove = () => setIsContextMenu(false);

  const viewerRef = useRef<ViewerRef | any>(null);
  const navRef = useRef<HTMLDivElement | null>(null);
  const optionRef = useRef<HTMLDivElement | null>(null);
  const pointerRef = useRef<{ time: number; position: number }>({
    time: 0,
    position: 0,
  });
  const stopNext = useRef<boolean>(false);
  const totalRef = useRef<number>(99999);

  const [localFile, setLocalFile] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(99999);
  const prevPageRef = useRef(0);
  const prevPageCFIRef = useRef<EpubCFI | null>(null);

  const isMobile = navigator.maxTouchPoints > 0

  const [bookStyle, setBookStyle] = useState<BookStyle>({
    fontFamily: 'Origin',
    fontSize: DEFAULT_FONT_SIZE,
    lineHeight: 1.4,
    marginHorizontal: 15,
    marginVertical: 5,
  });

  const [bookOption, setBookOption] = useState<BookOption>({
    flow: 'paginated',
    resizeOnOrientationChange: true,
    spread: 'auto',
  });

  const { selection, onAddHighlight, onRemoveHighlight, onUpdateHighlight } =
    useHighlight(viewerRef, setIsContextMenu, bookStyle, bookOption.flow);

  if (viewerRef.current) {
    viewerRef.current.style.opacity = '0%';
    if (viewerRef.current.children.length > 0) {
      if (viewerRef.current.children.length > 1) {
        viewerRef.current.childNodes.forEach((item: any, index: number) => {
          if (index === viewerRef.current.childNodes.length - 1) return;
          item.remove();
        });
      }
      viewerRef.current.children[0].style.pointerEvents = 'none';
      const iframeBody = viewerRef.current.children[0].querySelector('iframe')
        ?.contentDocument.body as HTMLBodyElement;
      if (
        iframeBody &&
        iframeBody.children.length === 1 &&
        iframeBody.getAttribute('epub:type') === 'cover'
      ) {
        iframeBody.style.opacity = '0%';
        iframeBody.style.display = 'flex';
        iframeBody.style.justifyContent = 'center';
        iframeBody.style.opacity = '100%';
      }
      viewerRef.current.style.opacity = '100%';
    }
  }

  useEffect(() => {
    sampleBook.ready.then(() => {
      const O_FACTOR =
        window.innerWidth < 600
          ? 26
          : window.innerWidth < 720
          ? 28
          : window.innerWidth < 888
          ? 30
          : window.innerWidth < 1024
          ? 26
          : window.innerWidth < 1200
          ? 30
          : 36;
      const charactersFactor =
        (window.innerWidth *
          W_FACTOR *
          ((window.innerHeight - 124) * H_FACTOR)) /
        (Number(bookStyle.fontSize) * F_FACTOR * O_FACTOR);
      sampleBook.locations
        .generate(charactersFactor)
        .then(() => {
          dispatch(
            updateCurrentPageNumber(
              getInitialPage(routerLocation).nowPage || 0,
            ),
          );
          setTotalPage((sampleBook.locations as any).total);
          totalRef.current = (sampleBook.locations as any).total;
          setTimeout(() => setLoading(false), 2000);
        })
        .catch(() => {
          setTimeout(() => setLoading(false), 2000);
        });
    });
  }, [
    sampleBook.locations,
    sampleBook.ready,
    bookStyle.fontSize,
    dispatch,
    routerLocation,
  ]);

  const [navControl, onNavToggle] = useMenu(navRef, 300);
  const [optionControl, onOptionToggle, emitEvent] = useMenu(optionRef, 300);

  /**
   * Change Epub book information
   * @param booky Epub Book Info
   */
  const onBookInfoChange = (booky: Book) => {
    setLoading(true);
    setTimeout(() => {
      const mainPage = getInitialPage(routerLocation);
      const initialPage = mainPage.page;
      if (!initialPage || initialPage.currentPage === 0) return;

      viewerRef.current.setLocation(initialPage.startCfi);

      dispatch(updateCurrentPage(initialPage));
    }, 2000);

    dispatch(updateBook(booky));
  };

  /**
   * Change Epub location
   * @param loc epubCFI or href
   */
  const onLocationChange = (loc: string) => {
    if (!viewerRef.current) return;
    viewerRef.current.setLocation(loc);
  };

  const onPageMove = (curPage: number) => {
    const node = viewerRef.current;

    const { startCfi, endCfi } = cfiRangeSpliter(
      sampleBook.locations.cfiFromLocation(curPage),
    ) || { startCfi: '', endCfi: '' };
    const epubcfi = startCfi.slice(8, -1);
    const pureCfi = epubcfi.replace(/\[.*?\]/gi, '');
    const splitCfi = pureCfi.split('!');
    const totalPage = totalRef.current;

    const page: Page = {
      startCfi,
      endCfi,
      base: splitCfi[0],
      totalPage: currentLocation.totalPage,
      currentPage: Math.round(
        (curPage / totalPage) * currentLocation.totalPage,
      ),
      chapterName: currentLocation.chapterName,
    };

    const query = new URLSearchParams(routerLocation.search);
    const spy = query.get('spy');

    const manyPages = Math.round((curPage / totalRef.current) * 100) >= 20;

    if (spy === 'true' && manyPages) return (stopNext.current = true);

    if (!manyPages) stopNext.current = false;

    if (curPage > 0) {
      setInitialPage(routerLocation, page, curPage);
    }
    node.setLocation(startCfi);
    dispatch(updateCurrentPage(page));
    dispatch(updateCurrentPageNumber(curPage));
  };

  /**
   * Set toc
   * @param toc Table of Epub contents
   */
  const onTocChange = (toc: Toc[]) => dispatch(updateToc(toc));

  /**
   * Set Epub viewer styles
   * @param bookStyle_ viewer style
   */
  const onBookStyleChange = (bookStyle_: BookStyle) => setBookStyle(bookStyle_);

  useEffect(() => {
    prevPageRef.current = currentPageNumber;
  }, [currentPageNumber])

  /**
   * Change current page
   * @param page Epub page
   */
  const onPageChange = (page: Page) => {
    const node = viewerRef.current;
    const nodeStyle = node.style;
    nodeStyle.position = 'relative';
    nodeStyle.transition = 'left 0.3s';

    const curPage = Number(sampleBook.locations.locationFromCfi(page.startCfi));
    const query = new URLSearchParams(routerLocation.search);
    const spy = query.get('spy');

    const manyPages = Math.round((curPage / totalRef.current) * 100) >= 20;

    if (spy === 'true' && manyPages) return (stopNext.current = true);

    const currentCFI = new EpubCFI(page.startCfi, page.endCfi)
    const prevCFI = prevPageCFIRef.current ?? currentCFI
    
    prevPageCFIRef.current = currentCFI
    
    const comparedValue = new EpubCFI().compare(currentCFI, prevCFI)

    const isLeft = comparedValue === -1
    const isRight = comparedValue === 1

    if (isRight || isLeft) nodeStyle.left = isRight ? '101vw' : '-101vw';

    setTimeout(() => {
      nodeStyle.top = '0'
    }, 200);

    setTimeout(() => {
      nodeStyle.left = '0vw'
    }, 400);

    if (!manyPages) stopNext.current = false;

    if (curPage > 0) {
      setInitialPage(routerLocation, page, curPage);
    }
    dispatch(updateCurrentPage(page));
    dispatch(updateCurrentPageNumber(curPage));
  };

  useEffect(() => {
    if (routerLocation.search) {
      const query = new URLSearchParams(routerLocation.search);

      setLocalFile(query.get('book') as string);
    }
  }, [routerLocation]);

  const handlePointerDown = (e: PointerEvent) => {
    pointerRef.current = {
      time: Date.now(),
      position: e.clientX,
    };
  };

  const handleClickEvent = (e: PointerEvent) => {
    if(isMobile) return

    viewerRef.current.style.top = '102vh'

    /*@ts-ignore*/
    const clientWidth = e.target.clientWidth
    const clickXPosition = e.x

    const clientMiddlePosition = clientWidth / 2

    const isRight = clickXPosition >= clientMiddlePosition
    const isLeft = clickXPosition < clientMiddlePosition

    isRight && viewerRef.current.nextPage()
    isLeft && viewerRef.current.prevPage()
  }

  const handlePointerUp = (e: PointerEvent) => {
    const node = viewerRef.current;
    const diff = e.clientX - pointerRef.current.position;

    if (Math.abs(diff) < 20) {
      handleClickEvent(e)

      return
    };

    node.style.top = '102vh'

    if (diff < 0 && !stopNext.current) {
      return node.nextPage();
    }
    if (diff > 0) {
      return node.prevPage();
    }
  };

  const handleKeydown = (e: KeyboardEvent) => {
    const keyboardKey = e.key

    const isLeft = keyboardKey === 'ArrowLeft'
    const isRight = keyboardKey === 'ArrowRight'

    if (!isLeft && !isRight) return

    viewerRef.current.style.top = '102vh'
  }

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.style.userSelect = 'none';
      viewerRef.current.style.touchAction = 'none';
      viewerRef.current.addEventListener('pointerdown', handlePointerDown);
      viewerRef.current.addEventListener('pointerup', handlePointerUp);
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (viewerRef.current) {
        viewerRef.current.removeEventListener('pointerdown', handlePointerDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        viewerRef.current.removeEventListener('pointerup', handlePointerUp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.removeEventListener('keydown', handleKeydown);
    }
    };
  });

  return (
    <>
      <ViewerWrapper>
        <Header onNavToggle={onNavToggle} onOptionToggle={onOptionToggle} />

        {loading && <Loader />}

        <ReactEpubViewer
          url={localFile}
          viewerLayout={viewerLayout}
          viewerStyle={bookStyle}
          viewerOption={bookOption}
          onBookInfoChange={onBookInfoChange}
          onPageChange={onPageChange}
          onTocChange={onTocChange}
          loadingView={loadingView || <LoadingView />}
          ref={viewerRef}
        />

        <Footer
          // title={currentLocation.chapterName}
          nowPage={currentPageNumber}
          totalPage={totalPage}
          onPageMove={onPageMove}
        />
      </ViewerWrapper>

      <Nav
        control={navControl}
        onToggle={onNavToggle}
        onLocation={onLocationChange}
        ref={navRef}
      />

      <Option
        control={optionControl}
        bookStyle={bookStyle}
        bookFlow={bookOption.flow}
        onToggle={onOptionToggle}
        emitEvent={emitEvent}
        onBookStyleChange={onBookStyleChange}
        ref={optionRef}
      />

      <ContextMenu
        active={isContextMenu}
        viewerRef={viewerRef}
        selection={selection}
        onAddHighlight={onAddHighlight}
        onRemoveHighlight={onRemoveHighlight}
        onUpdateHighlight={onUpdateHighlight}
        onContextmMenuRemove={onContextmMenuRemove}
      />

      <Snackbar />
    </>
  );
};

const ReaderWrapper = ({ loadingView }: Props) => {
  return (
    <Provider store={store}>
      <Reader loadingView={loadingView} />
    </Provider>
  );
};

interface Props {
  loadingView?: React.ReactNode;
}

export default ReaderWrapper;
