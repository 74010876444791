import styled from 'styled-components'
// lib
import palette from 'lib/styles/palette'

const Wrapper = styled.div<{ios: boolean}>`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${({ ios }) => ios ? '80px' : '60px'};
  padding: ${({ ios }) => ios ? '0 3% 25px' : '0 3% 10px'};
  gap: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${palette.white};
`;

export default Wrapper