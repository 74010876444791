import { Container } from "./styles";
import { TailSpin } from  'react-loader-spinner'

export const Loader = () => {
  return (
    <Container>
      <TailSpin color="#00BFFF" height={80} width={80} />
    </Container>
  );
};
