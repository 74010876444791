import React from 'react';
import { useState, useEffect } from 'react';
// components
import Wrapper from 'components/sideMenu/Wrapper';
import OptionLayout from 'components/option/Layout';
import OptionDropdown from 'components/option/Dropdown';
import OptionSlider from 'components/option/Slider';
// types
import { BookStyle, BookFontFamily, BookFlow } from 'types/book';
import { MenuControl } from 'lib/hooks/useMenu';

const Option = (
  {
    control,
    bookStyle,
    bookFlow,
    onToggle,
    emitEvent,
    onBookStyleChange,
  }: Props,
  ref: any,
) => {
  const [fontFamily, setFontFamily] = useState<BookFontFamily>(
    bookStyle.fontFamily,
  );
  const [fontSize, setFontSize] = useState<number>(bookStyle.fontSize);
  const [lineHeight, setLineHeight] = useState<number>(bookStyle.lineHeight);
  const [marginHorizontal, setMarginHorizontal] = useState<number>(
    bookStyle.marginHorizontal,
  );
  const [marginVertical, setMarginVertical] = useState<number>(
    bookStyle.marginVertical,
  );

  /** Change font family */
  const onSelectFontFamily = (font: BookFontFamily) => setFontFamily(font);

  /** Change font style and layout */
  const onChangeSlider = (type: SliderType, e: any) => {
    if (!e || !e.target) return;
    switch (type) {
      case 'FontSize':
        setFontSize(e.target.value);
        break;
      case 'LineHeight':
        setLineHeight(e.target.value);
        break;
      case 'MarginHorizontal':
        setMarginHorizontal(e.target.value);
        break;
      case 'MarginVertical':
        setMarginVertical(e.target.value);
        break;
      default:
        break;
    }
  };

  /* Save userdata */
  // TODO Fix the infinite re-rendering issue, when inlcude `onBookStyleChange` to dependencies array.
  /* eslint-disable */
  useEffect(() => {
    const timer = window.setTimeout(() => {
      onBookStyleChange({
        fontFamily,
        fontSize,
        lineHeight,
        marginHorizontal,
        marginVertical,
      });
    }, 250);

    return () => window.clearTimeout(timer);
  }, [fontFamily, fontSize, lineHeight, marginHorizontal, marginVertical]);
  /* eslint-enable */

  /** Re-register close event, when after set */
  useEffect(() => emitEvent(), [bookStyle, emitEvent]);

  return (
    <>
      {control.display && (
        <Wrapper
          title="Configurações"
          show={control.open}
          onClose={onToggle}
          ref={ref}
        >
          <OptionLayout>
            <OptionDropdown
              title="Fonte"
              defaultValue={fontFamily}
              valueList={['Origin', 'Roboto']}
              onSelect={onSelectFontFamily}
            />
            <OptionSlider
              active={true}
              title="Tamanho"
              minValue={8}
              maxValue={36}
              defaultValue={fontSize}
              step={1}
              onChange={e => onChangeSlider('FontSize', e)}
            />
            <OptionSlider
              active={true}
              title="Altura da linha"
              minValue={1}
              maxValue={3}
              defaultValue={lineHeight}
              step={0.1}
              onChange={e => onChangeSlider('LineHeight', e)}
            />
            <OptionSlider
              active={true}
              title="Margem Horizontal"
              minValue={0}
              maxValue={100}
              defaultValue={marginHorizontal}
              step={1}
              onChange={e => onChangeSlider('MarginHorizontal', e)}
            />
            <OptionSlider
              active={bookFlow === 'paginated'}
              title="Margem Vertical"
              minValue={0}
              maxValue={100}
              defaultValue={marginVertical}
              step={1}
              onChange={e => onChangeSlider('MarginVertical', e)}
            />
          </OptionLayout>
        </Wrapper>
      )}
    </>
  );
};

interface Props {
  control: MenuControl;
  bookStyle: BookStyle;
  bookFlow: BookFlow;
  onToggle: () => void;
  emitEvent: () => void;
  onBookStyleChange: (bookStyle: BookStyle) => void;
}

type SliderType =
  | 'FontSize'
  | 'LineHeight'
  | 'MarginHorizontal'
  | 'MarginVertical';

export default React.forwardRef(Option);
