// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import Reader from 'containers/Reader';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Reader />} />
    </Routes>
  </Router>,
  // container
);
