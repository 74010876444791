import { Location } from "react-router-dom";
import Page from "types/page";

const getInitialPage = (location: any) => {
  const query = new URLSearchParams(location.search);
  const book = query.get("book");

  if(!book) return false;

  const books = localStorage.getItem('@vml/books');
  if(!books) return false;

  const currentPage = JSON.parse(books)[book];

  if(!currentPage) return false;

  return currentPage;
};

const setInitialPage = (location: Location, page: Page, nowPage: number, total?: number) => {
  const query = new URLSearchParams(location.search);
  const book = query.get("book");
  if(!book) return false;

  const books = localStorage.getItem('@vml/books');
  if(!books) {
    return localStorage.setItem('@vml/books', JSON.stringify({
      [book]: {
        page,
        nowPage,
        total
      }
    }));
  }

  const newBooks = JSON.parse(books);
  newBooks[book] = {
    page,
    nowPage,
    total
  };

  localStorage.setItem('@vml/books', JSON.stringify(newBooks));
};

export { getInitialPage, setInitialPage };
