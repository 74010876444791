// components
import Wrapper from 'components/header/Wrapper';
import Layout, { AutoLayout } from 'components/header/Layout';
import { IoSettings } from 'react-icons/io5';
import styled from 'styled-components';

import * as styles from 'lib/styles/styles';
import palette from 'lib/styles/palette';

const Btn = styled.button`
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ${styles.transition};
  outline: none;

  &:focus,
  &:hover {
    color: ${palette.blue3};
  }

  svg {
    &:focus,
    &:hover {
      padding: 8px;
      box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
      border-radius: 50px;
    }
  }

  &:first-child {
    margin-left: 8px;
  }
`;

const Header = ({ onNavToggle, onOptionToggle }: Props) => {
  return (
    <Wrapper>
      <Layout>
        <AutoLayout>
          <div />
          <div>
            <Btn onClick={() => onNavToggle()}>Capítulos</Btn>
            <Btn onClick={() => onOptionToggle()}>
              <IoSettings size={20} />
            </Btn>
          </div>
        </AutoLayout>
      </Layout>
    </Wrapper>
  );
};

interface Props {
  onNavToggle: (value?: boolean) => void;
  onOptionToggle: (value?: boolean) => void;
}

export default Header;
