import styled from 'styled-components'
import ReactSlider from 'react-slider'

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 5px;
`

export const StyledThumb = styled.div`
	translate: 0% calc(2.5px - 50%);

    height: 15px;
    width: 15px;

    background-color: darkcyan;
    border-radius: 50%;

    cursor: grab;

    outline: none;
`


export const StyledTrack = styled.div<{ index: number }>`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 1 ? '#ddd' : 'darkcyan')};
    border-radius: 2.5px;
`
