// components
import Wrapper from 'components/footer/Wrapper';
import Item from 'components/footer/Item';
import { ProgressBar } from 'components/ProgressBar';
import { isIOSDevice } from 'utils/device'

const Footer = ({ nowPage, totalPage, onPageMove }: Props) => {
  return nowPage !== undefined &&
    totalPage !== 99999 &&
    totalPage !== undefined ? (
    <Wrapper ios={isIOSDevice}>
      <Item
        text={`${Math.round(
          (nowPage / (nowPage > totalPage ? nowPage : totalPage)) * 100,
        )}%`}
      />
      <ProgressBar
        onPageMove={onPageMove}
        curPage={nowPage}
        totalPage={totalPage}
      />
      <Item
        text={`${nowPage} / ${nowPage > totalPage ? nowPage : totalPage}`}
      />
    </Wrapper>
  ) : (
    <></>
  );
};

interface Props {
  nowPage: number;
  totalPage: number;
  onPageMove: (page: number) => void;
}

export default Footer;
