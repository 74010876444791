import { StyledSlider, StyledThumb, StyledTrack } from './styles';

interface Props {
  curPage: number;
  totalPage: number;
  onPageMove: (page: number) => void;
}

const Thumb = (props: any) => <StyledThumb {...props} />;

const Track = (props: any, state: any) => (
  <StyledTrack {...props} index={state.index} />
);

export const ProgressBar = ({ curPage, totalPage, onPageMove }: Props) => {
  return (
    <StyledSlider
      value={curPage}
      onAfterChange={value => onPageMove((value || 1) as number)}
      max={totalPage}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  );
};
